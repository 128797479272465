@font-face {
  font-family: 'Lato';
  src: url('./assets//fonts/Lato/Lato-Regular.ttf') format('trueType');
}

body {
  margin: 0;
  font-family: 'Lato';
  font-size: 62.5%;
}

iframe {
  display: block;
  /* iframes are inline by default */
  border: none;
  /* Reset default border */
  height: 100%;
  /* Viewport-relative units */
  width: 70%;
}

html,
body,
#root {
  min-width: 100%;
  height: 100%;
  position: relative;
  background: #f8f8f8;

  /* Add CSS for custom fonts */
  .ql-font-sans-serif {
    font-family: sans-serif;
  }

  .ql-font-serif {
    font-family: serif;
  }

  .ql-font-monospace {
    font-family: monospace;
  }

  .ql-font-courier {
    font-family: Courier, monospace;
  }

  .ql-font-georgia {
    font-family: Georgia, serif;
  }

  .ql-font-times-new-roman {
    font-family: 'Times New Roman', Times, serif;
  }

  .ql-font-verdana {
    font-family: Verdana, sans-serif;
  }

  /* Ensure custom font names are displayed correctly in the toolbar */
  .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before {
    content: 'Sans Serif';
  }

  .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before {
    content: 'Serif';
  }

  .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before {
    content: 'Monospace';
  }

  .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before {
    content: 'Arial';
  }

  .ql-picker.ql-font .ql-picker-label[data-value="courier"]::before {
    content: 'Courier';
  }

  .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before {
    content: 'Georgia';
  }

  .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before {
    content: 'Times New Roman';
  }

  .ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before {
    content: 'Verdana';
  }

  .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
    content: 'Sans Serif';
  }

  .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
    content: 'Serif';
  }

  .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
    content: 'Monospace';
  }

  .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
    content: 'Arial';
  }

  .ql-picker.ql-font .ql-picker-item[data-value="courier"]::before {
    content: 'Courier';
  }

  .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
    content: 'Georgia';
  }

  .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
    content: 'Times New Roman';
  }

  .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
    content: 'Verdana';
  }

  .upload-menu {
    .MuiMenu-paper {
      top: 16px !important;
      right: 100px !important;
      left: auto !important;
      width: 10rem;
    }
  }
}